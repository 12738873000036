import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "store/userSlice";
import { jwtService } from "app/auth/jwtService";

/**
 * Form Validation Schema
 */

const defaultValues = {
    username: "",
    password: ""
};

function SignInPage() {

    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const { control, handleSubmit } = useForm({
        defaultValues: defaultValues
    });
    const errorMessage = useSelector((state => state.user.errorMessage))


    function onSubmit({ username, password }) {
        if (!username || !password) {
            dispatch(setErrorMessage("Παρακαλώ εισάγετε Username και Password"));
            return;
        }

        jwtService
            .signInWithEmailAndPassword(username, password)
            .then((user) => {
                // No need to do anything, user data will be set at app/auth/AuthContext
            })
            .catch((error) => {
                // Update the error message state
                dispatch(setErrorMessage(error.message));
            });
    }


    return (
        <div
            className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0 h-screen">
            <Paper
                className="flex items-center justify-center w-full sm:w-1/2 h-full py-8 px-4 sm:px-16 md:px-24 lg:px-32 xl:px-48 2xl:px-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none">
                <div className=" max-w-240 mx-auto">
                    <div className="flex items-center justify-center h-full">
                        <img

                            src={`${process.env.PUBLIC_URL}/logo/Ypourgeio1555.webp`}
                            alt="logo"
                        />
                    </div>


                    <Typography className=" text-4xl text-center font-extrabold tracking-tight leading-tight">
                        Είσοδος Χρήστη
                    </Typography>
                    <form
                        name="loginForm"
                        noValidate
                        className="flex flex-col justify-center w-full mt-8"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="max-w-[420px] w-full mx-auto">
                            <Controller
                                name="username"
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        className="mb-8"
                                        label="Username"
                                        autoFocus
                                        autoComplete="new-username"
                                        type="username"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputLabelProps={{shrink: true}}
                                    />
                                )}
                            />

                            <Controller
                                name="password"
                                control={control}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        className="mb-4"
                                        label="Password"
                                        autoComplete="new-password"
                                        type={showPassword ? "text" : "password"}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputLabelProps={{shrink: true}}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </div>

                        <div className="max-w-[420px] w-full mx-auto">

                            <Button
                                variant="contained"
                                color="secondary"
                                className="w-full mt-4 rounded-full bg-[#003476]"
                                aria-label="Sign in"
                                type="submit"
                                size="large"
                                style={{textTransform: 'capitalize'}}

                            >
                                Είσοδος Χρήστη
                            </Button>


                            <Typography className="text-center mt-4">ή</Typography>
                            <div>
                                <Button
                                    variant="contained"
                                    className="w-full mt-4 rounded-full bg-orange-500  hover:bg-orange-500 text-white flex justify-center items-center"
                                    aria-label="Σύνδεση με Κωδικούς Δημόσιας Διοίκησης"
                                    size="large"
                                    style={{textTransform: 'capitalize'}}

                                    onClick={() => {
                                        window.location.href = process.env.REACT_APP_TAXIS_LOGIN;
                                    }}

                                >
                                    {/* <img
                                    src={`${process.env.PUBLIC_URL}/logo/ggps.png`}
                                    alt="Icon"
                                    className="w-20 h-20 mr-2"
                                /> */}
                                    Σύνδεση με Κωδικούς Δημόσιας Διοίκησης
                                </Button>
                            </div>

                            {errorMessage && (
                                <Typography
                                    className="mt-4 mb-4 text-red-600 text-center"
                                    variant="body2"
                                >
                                    {errorMessage}
                                </Typography>
                            )}
                        </div>

                    </form>

                    <img
                        src={`${process.env.PUBLIC_URL}/logo/espanewlogo.png`}
                        alt="logo"
                        className="mt-4 mx-auto"
                    />
                </div>

            </Paper>

            <Box
                className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
                sx={{backgroundColor: "primary.light"}}
            >
                <svg
                    className="absolute inset-0 pointer-events-none"
                    viewBox="0 0 960 540"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMidYMax slice"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <Box
                        component="g"
                        sx={{color: "primary.main"}}
                        className="opacity-20"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="100"
                    >
                        <circle r="234" cx="196" cy="23"/>
                        <circle r="234" cx="790" cy="491"/>
                    </Box>
                </svg>
                <Box
                    component="svg"
                    className="absolute -top-64 -right-64 opacity-20"
                    sx={{color: "primary.light"}}
                    viewBox="0 0 220 192"
                    width="220px"
                    height="192px"
                    fill="none"
                >
                    <defs>
                        <pattern
                            id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                            x="0"
                            y="0"
                            width="20"
                            height="20"
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x="0" y="0" width="4" height="4" fill="currentColor"/>
                        </pattern>
                    </defs>
                    <rect
                        width="220"
                        height="192"
                        fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                    />
                </Box>
            </Box>
        </div>
    );
}

export default SignInPage;
