import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchThematicsByCarrierId,
  setSelectedSubSubthematic,
  setSelectedSubthematic,
  setSelectedThematic, setSubSubthematics, setSubthematics, setTemplateButtonVisible,
} from 'store/knowledgeBasePageSlice';
import CategoriesDropdown from '../CategoriesDropdown';
import settingsConfig from "app/configs/settingConfig";
import TemplateDialog from "app/main/tickets/create-ticket/TemplateDialog";

function ThematicSelector({ isCreatePage }) {
  const dispatch = useDispatch();
  const selectedCarrier = useSelector((state) => state.knowledgeBasePageSlice.selectedCarrier);
  const thematics = useSelector((state) => state.knowledgeBasePageSlice.thematics);
  const isLoadingThematics = useSelector(
      (state) => state.knowledgeBasePageSlice.isLoadingThematics
  );
  const selectedThematic = useSelector((state) => state.knowledgeBasePageSlice.selectedThematic);
  const activeStatus = useSelector((state) => state.ticketSlice.activeStatus);
  const currentUserRole = useSelector((state) => state.user.roleName);


  useEffect(() => {
    if (selectedCarrier) {
      dispatch(fetchThematicsByCarrierId({ isCreatePage, carrierId: selectedCarrier.value }));
    } else {
      dispatch(setSelectedThematic(null));
      dispatch(setSelectedSubthematic(null));
      dispatch(setSelectedSubSubthematic(null));
      dispatch(setSubSubthematics([]));
      dispatch(setSubthematics([]));
    }
  }, [selectedCarrier, dispatch]);

  const handleChange = (selectedOption) => {
    dispatch(setSelectedThematic(selectedOption));
    dispatch(setSelectedSubthematic(null));
    dispatch(setSelectedSubSubthematic(null));
  };



  const isDropdownDisabled = () => {
    if (isCreatePage) return false;

    if (activeStatus && activeStatus.id === 5) return true;

    const allowedRoles = [...settingsConfig.roles.supervisor, ...settingsConfig.roles.teamLeader];
    return !allowedRoles.includes(currentUserRole);
  };

  return (
      <>
        {selectedCarrier && thematics.length > 0 && (
            <>
              <CategoriesDropdown
                  options={thematics.map((thematic) => ({
                    value: thematic.id,
                    label: thematic.title,
                    answerTextTemplateCollection: thematic?.answerTextTemplateCollection,
                    isDisabled: !thematic.isActive
                  }))}
                  isDisabled={isDropdownDisabled()}
                  value={selectedThematic}
                  onChange={(selectedOption) => handleChange(selectedOption)}
                  placeholder="Θεματική Ενότητα"
                  backgroundColor="white"
                  isLoading={isLoadingThematics}
              />
            </>
        )}
      </>
  );
}

export default ThematicSelector;