import { createSlice } from "@reduxjs/toolkit";

const historyTicketSlice = createSlice({
  name: "tickets",
  initialState: {
    ticketsData: {},
    historyTickets: [],
    thematics: {},
    users: {},
    actionCodes: {},
    pagination: {},
    historyDataFetched: {},
    selectedCarrier: null,
    filteredTickets: [],
    followupTicketId: { id: null, uuid: null, },
    isFollowUpModalOpen: false,
  },
  reducers: {
    setTicketsData(state, action) {
      const { beneficiaryId, page, data, isFiltered, selectedCarrier } = action.payload;
      if (!state.ticketsData[beneficiaryId]) {
        state.ticketsData[beneficiaryId] = {};
      }
      state.ticketsData[beneficiaryId][page] = {
        data,
        isFiltered,
        selectedCarrier,
      };
    },

    filterTickets(state, action) {
      const { beneficiaryId, currentPage, isFilteredByStatus, selectedCarrier } = action.payload;
      const beneficiaryTicketsData = state.ticketsData[beneficiaryId] || {};
      const currentPageData = beneficiaryTicketsData[currentPage - 1] || {};
      state.filteredTickets = (currentPageData.data || []).filter(ticket =>
          (isFilteredByStatus ? [4, 8].includes(ticket.ticketStatus.id) : true) &&
          (selectedCarrier ? ticket.thematic?.carrier?.id === selectedCarrier : true)
      );
    },
    setFollowupTicketId(state, action) { const { payload } = action; const id = payload?.id ?? null; const uuid = payload?.uuid ?? null; state.followupTicketId = { id, uuid }; },
    setHistoryTickets(state, action) {
      state.historyTickets = action.payload;
    },
    setSelectedCarrier(state, action) {
      state.selectedCarrier = action.payload;
    },
    setThematics(state, action) {
      state.thematics = { ...state.thematics, ...action.payload };
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setActionCodes(state, action) {
      state.actionCodes = action.payload;
    },
    clearTicketsData(state) {
      state.ticketsData = {};
      state.historyTickets = [];
      state.thematics = {};
      state.users = {};
      state.actionCodes = {};
      state.historyDataFetched = {};
      state.pagination = {};  // Clear pagination data as well
      state.selectedCarrier = null;
    },
    clearOtherData(state) {
      state.ticketsData = {};
      state.historyTickets = [];
      state.actionCodes = {};
      state.historyDataFetched = {};
      state.pagination = {};  // Clear pagination data as well
      state.selectedCarrier = null;
    },
    setPaginationData(state, action) {
      const { beneficiaryId, totalPages, totalNumberOfTickets, currentPage } = action.payload;
      state.pagination[beneficiaryId] = {
        totalPages,
        totalNumberOfTickets,
        currentPage
      };
    },
    setHistoryDataFetched(state, action) {
      const { beneficiaryId, ticketId } = action.payload;

      if (!state.historyDataFetched[beneficiaryId]) {
        state.historyDataFetched[beneficiaryId] = {};
      }

      if (!state.historyDataFetched[beneficiaryId][ticketId]) {
        state.historyDataFetched[beneficiaryId][ticketId] = true;
      }
    },
    setFollowUpModalState(state, action) {
      state.isFollowUpModalOpen = action.payload;
    },
  },
});

export const {
  setTicketsData,
  setHistoryTickets,
  setThematics,
  setUsers,
  setActionCodes,
  clearTicketsData,
  clearOtherData,
  setHistoryDataFetched,
  setSelectedCarrier,
  filterTickets,
  setPaginationData,
    setCurrentPage,
  setFollowUpModalState,
  setFollowupTicketId
} = historyTicketSlice.actions;

export default historyTicketSlice.reducer;
