import { red, blue, grey, indigo, purple } from '@mui/material/colors';
import WarningIcon from '@mui/icons-material/Warning';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import CallEndIcon from '@mui/icons-material/CallEnd';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import ReportIcon from '@mui/icons-material/Report';
import BugReportIcon from '@mui/icons-material/BugReport';
import CampaignIcon from '@mui/icons-material/Campaign';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import EventIcon from '@mui/icons-material/Event';
import AnnouncementIcon from '@mui/icons-material/Announcement';

const getFlagColor = (flagCode) => {
    switch (flagCode) {
      case 100://Red Alert
        return { color: red[500], icon: WarningIcon };
        case 101://Priority
        return { color:'#3ac376', icon: PriorityHighIcon };
      case 102://Priority
        return { color:'#3AC3B8FF', icon: DirectionsWalkIcon };
      case 103://Priority
        return { color:'#be3ac3', icon: SentimentVeryDissatisfiedIcon };
      case 104://ΔΕΘ
        return { color:'#003476', icon: EventIcon };
      case 200://Follow Up
        return { color: blue[500], icon: AirlineStopsIcon };
      case 300://Ελλειψεις
        return { color: grey[500], icon: BugReportIcon};
      case 400://Αιτημα Ηχογ/μενης κλήσης Προσοχή
        return { color: '#e65100', icon: CallEndIcon };
      case 500://Πρόβλημα πληροφοριακών Συστημάτων
        return { color: indigo[500], icon: RingVolumeIcon };
      // case 600://Έπεση η γραμμή
      //   return { color: purple[500], icon: PhoneDisabledIcon };
      // case 700://Callback
      //   return { color: blue[500], icon: RecordVoiceOverIcon };
      case 800://Παράπονα για το 1555
        return { color: '#ba000d', icon: CampaignIcon };
      case 900://Καταγγελία Πολίτη
        return { color: grey[900], icon: ReportIcon };
      case 1000: // Media Alert / Special Case
        return { color: '#FF6F61', icon: AnnouncementIcon };
      default:
        return { color: "blue", icon: null };
    }
};

export default getFlagColor;
