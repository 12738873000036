import {Accordion, AccordionDetails, AccordionSummary, Grid} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getSpecificUser } from "app/Api Calls/GetSpecificUser";
import { setTeam } from "store/editTicketSlice";
import { useSelector } from "react-redux";
import { fetchTeamById } from "app/Api Calls/GetTeamById";
import FlagsTicket from "app/main/Reusable Components/Ticket Reusable/FlagsTicket";
import { setErotisiEditorState, validateErotisiText } from "store/userInfoSlice";
import TicketStatus from "app/main/Reusable Components/Ticket Reusable/TicketStatus";
import CommentsEditAccordion from "./CommentsEditAccordion";
import { fetchChildTicketsByParentId, fetchParentTicketById } from "app/Api Calls/FollowUpIds";
import ButtonBase from "@mui/material/ButtonBase";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography, Button } from "@mui/material";
import {setFollowupTicketId} from "store/historyTicketSlice";

function TicketEditAccordion({
                               id,
                               ticket,
                               expandedPanels,
                               onPanelToggle,
                               panelRefs,
                               callerId,
                             }) {
  const dispatch = useDispatch();
  const team = useSelector((state) => state.editTicketSlice.team);
  const [userDetails, setUserDetails] = useState(null);
  const [parentTickets, setParentTickets] = useState([]);
  const [childTickets, setChildTickets] = useState([]);

  useEffect(() => {
    if (ticket && ticket.description) {
      dispatch(setErotisiEditorState(ticket.description));
      dispatch(validateErotisiText(ticket.description));
    }
    if (ticket && ticket.assigneeUserId) {
      async function fetchUserDetails() {
        try {
          const fetchedUserDetails = await getSpecificUser(ticket.assigneeUserId);
          setUserDetails(fetchedUserDetails);
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
      fetchUserDetails();
    }
    if (ticket && ticket.teamId) {
      async function fetchTeamDetails() {
        try {
          const fetchedTeam = await fetchTeamById(ticket.teamId);
          dispatch(setTeam(fetchedTeam));
        } catch (error) {
          console.error("Error fetching team details:", error);
        }
      }
      fetchTeamDetails();
    }
    if (ticket?.hasParent || ticket?.hasChild) {
      fetchParentAndChildTickets(ticket);
    }
  }, [dispatch, id, ticket]);

  const fetchParentAndChildTickets = async (ticket) => {
    const { id: ticketId, hasParent, hasChild } = ticket;

    // Fetch parent tickets
    if (hasParent) {
      try {
        const parentData = await fetchParentTicketById(ticketId);
        const normalizedParentArray = Array.isArray(parentData) ? parentData : [parentData].filter(Boolean);
        setParentTickets(normalizedParentArray);

        if (normalizedParentArray.length > 0) {
          const firstParent = {
            id: normalizedParentArray[0]?.id,
            uuid: normalizedParentArray[0]?.uuid,
          };
          dispatch(setFollowupTicketId(firstParent));
        }
      } catch (error) {
        if (error.response?.status === 404) {
          console.warn(`No parent tickets found for ticket ID: ${ticketId}`);
        } else {
          console.error("Error fetching parent tickets:", error.message);
        }
        setParentTickets([]);
      }
    }

    // Fetch child tickets
    if (hasChild) {
      try {
        const childData = await fetchChildTicketsByParentId(ticketId);
        setChildTickets(Array.isArray(childData) ? childData : []);
      } catch (error) {
        if (error.response?.status === 404) {
          console.warn(`No child tickets found for ticket ID: ${ticketId}`);
        } else {
          console.error("Error fetching child tickets:", error.message);
        }
        setChildTickets([]);
      }
    }
  };

  const handleNavigateToTicket = (ticketId) => {
    window.location.href = `/ticket/${ticketId}`;
  };

  return (
      <Accordion
          className="bg-gray-200"
          expanded={expandedPanels.includes("Τicket")}
          onChange={() => onPanelToggle("Τicket")}
          ref={panelRefs["Τicket"]}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h3>Ticket</h3>
        </AccordionSummary>
        <AccordionDetails>
          {/* Parent Tickets */}
          {parentTickets.length > 0 && (
              <Grid container spacing={2} sx={{ mb: 2 }}>
                {parentTickets.map((parent) => (
                    <Grid item key={parent.id}>
                      <ButtonBase
                          onClick={() => handleNavigateToTicket(parent.id)}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            gap: 1,
                            backgroundColor: '#f97316 !important',
                            color: 'white',
                            padding: '8px',
                            borderRadius: '8px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                            width: 'fit-content',
                            '&:hover': {
                              backgroundColor: '#fb8c00 !important',
                            },
                          }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography variant="body2" sx={{ fontSize: '14px' }}>
                            <strong>Follow Up από:</strong> {parent.uuid}
                          </Typography>
                          <Box sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
                            <ArrowForwardIcon />
                          </Box>
                        </Box>
                      </ButtonBase>
                    </Grid>
                ))}
              </Grid>
          )}

          {/* Child Tickets */}
          {childTickets.length > 0 && (
              <Grid container spacing={2} sx={{ mb: 2 }}>
                {childTickets.map((child) => (
                    <Grid item key={child.id}>
                      <ButtonBase
                          onClick={() => handleNavigateToTicket(child.id)}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            backgroundColor: '#4caf50 !important',
                            color: 'white',
                            padding: '8px 12px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                            '&:hover': {
                              backgroundColor: '#388e3c',
                            },
                          }}
                      >
                        <Typography variant="body2">
                          <strong>Follow up σε:</strong> {child.uuid}
                        </Typography>
                        <ArrowForwardIcon sx={{ color: 'white' }} />
                      </ButtonBase>
                    </Grid>
                ))}
              </Grid>
          )}

          {/* Ticket Information */}
          <Grid container spacing={2}>
            {/* Ticket Number and Assigned User */}
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1">
                <strong>Αριθμός Ticket:</strong> {ticket?.uuid || "N/A"}
              </Typography>
              {userDetails && (
                  <Typography variant="subtitle1"  sx={{ mt: 1 }}>
                    <strong>Ανατεθειμένο σε:</strong> {userDetails.firstName} {userDetails.lastName} (
                    {userDetails.roleCollection[0]?.roleName
                        ?.replace('ROLE_', '')
                        .replace(/_/g, ' ')}
                    )
                    {team && (
                        <>
                          {' '}
                          - <strong>Ομάδα:</strong> {team.name}
                        </>
                    )}
                  </Typography>
              )}
            </Grid>

            {/* Caller ID */}
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1">
                <strong>Εισερχόμενο Τηλέφωνο:</strong> {callerId === -1 ? 'Απόκρυψη Αριθμού' : callerId}
              </Typography>
            </Grid>

            {/* Ticket Status and Date */}
            <Grid item xs={12} sm={6} md={4}>
              <TicketStatus
                  isEdit
                  ticketStatusId={ticket ? ticket.ticketStatusId : null}
                  ticketId={ticket ? ticket.id : null}
              />
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>Ημερομηνία Καταχώρησης:</strong> {ticket && new Date(ticket.createdAt).toLocaleDateString('el-GR')}
              </Typography>
            </Grid>

            {/* Flags Section */}
            <Grid item xs={12} sx={{ mt: 4 }}>
              <FlagsTicket isEditPage={true} />
            </Grid>

            {/* Ticket Description */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Ερώτηση Ticket
              </Typography>
              <div
                  className="description-container overflow-auto"
                  style={{
                    maxHeight: '200px',
                    minHeight: '200px',
                    backgroundColor: 'white',
                    padding: '16px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                        ticket && ticket.description !== null && ticket.description.trim() !== ''
                            ? ticket.description
                            : '<p></p>',
                  }}
              />
              <CommentsEditAccordion
                  id={id}
                  sourceApplication={ticket?.sourceApplication?.id}
                  status={ticket?.status}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

  );
}

export default TicketEditAccordion;
