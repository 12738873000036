import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar } from '@mui/material';
import { setAllowNavigation, setShowCheckForChanges } from 'store/editTicketSlice';
import assignTeamToTicket from 'app/Api Calls/AssignTeamToTicket';
import updateTicket from 'app/Api Calls/UpdateTicket';
import { showMessage } from 'store/messageSlice';

function CheckForChanges({ editMode, onCompletion, selectedSubThema, groupAssignment, onCloseModal }) {
  const dispatch = useDispatch();
  const originalTicket = useSelector((state) => state.editTicketSlice.ticket);
  const [actionStatus, setActionStatus] = useState({completed: false, success: false});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const selectedResolution = useSelector(
      (state) => state.resolutionsSlice.selectedResolution
  );
  const erotisi = useSelector((state) => state.userInfoSlice.erotisi);
  const [currentStep, setCurrentStep] = useState(null);
  const activeFlags = useSelector((state) => state.knowledgeBasePageSlice.activeFlags)
  const thematicId = useSelector(
      (state) =>
          selectedSubThema ||
          state.knowledgeBasePageSlice.selectedSubSubthematic?.value ||
          state.knowledgeBasePageSlice.selectedSubthematic?.value ||
          state.knowledgeBasePageSlice.selectedThematic?.value ||
          null
  );
  const selectedGeography = useSelector((state) => state.ticketSlice.selectedGeography);
  const beneficiaryId = useSelector(
      (state) => state.userInfoSlice.beneficiaryId
  );
  const showCheckForChanges = useSelector((state) => state.editTicketSlice.showCheckForChanges);
  const selectedUserForDirectAssignment = useSelector((state) => state.editTicketSlice.selectedUserForDirectAssignment);
  const activeStatus = useSelector((state) => state.ticketSlice.activeStatus);
  const [newBeneficiaryId, setNewBeneficiaryId] = useState(null);
  const selectedTeam = useSelector((state) => state.editTicketSlice.selectedTeamForDirectAssignment);
  const selectedThematic = useSelector((state) => state.knowledgeBasePageSlice.selectedThematic);

  useEffect(() => {
    if (showCheckForChanges) {
      if (selectedUserForDirectAssignment) {
        // If we have a user for direct assignment, update the ticket
        updateTicketData();
      } else if (groupAssignment) {
        // If its a group assignment, set current step to group assignment
        if (selectedSubThema != null && selectedSubThema !== originalTicket.thematicId) {
          updateTicketData();
        }


        if(selectedTeam) {
           setCurrentStep('groupAssignmentStep');
         }
      } else {
        // Otherwise, proceed to final action
        setCurrentStep('finalAction');
      }
    }

    dispatch(setAllowNavigation(true));

  }, [showCheckForChanges]);


  // Handle completion of each step
  useEffect(() => {
    switch (currentStep) {
      case 'groupAssignmentStep':
        if (groupAssignment) {
          performGroupAssignmentStep();
        }
        break;
      case 'finalAction':
        handleFinalAction();
        break;
      default:
        break;
    }
  }, [currentStep]);


  const performGroupAssignmentStep = async () => {
    if (groupAssignment && selectedTeam) {
      try {
        const responseStatus = await assignTeamToTicket(originalTicket.id, selectedTeam.id);
        if (responseStatus === 200) {
          // If the operation was successful
          setActionStatus({ completed: true, success: true });
          setOpenSnackbar(true);
          setCurrentStep('finalAction');
        } else {
          setActionStatus({ completed: true, success: false });
          setOpenSnackbar(true);
          setCurrentStep('finalAction');
        }
      } catch (error) {
        console.error("Error during team assignment:", error);
        // Handle the error state
        setActionStatus({ completed: true, success: false });
        setOpenSnackbar(true);
        setCurrentStep('finalAction');
      }
    }
  };



  const updateTicketData = async () => {
    if (!erotisi || erotisi.trim() === '') {
      dispatch(showMessage({
        message: "Η ερώτηση είναι απαραίτητη για την ενημέρωση του ticket",
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        variant: "error",
      }));
      return;
    }
    try {
      const teamIdToUse = selectedTeam?.id || selectedUserForDirectAssignment?.teamId || originalTicket.teamId;

      const response = await updateTicket(
          originalTicket.id,
          selectedResolution,
          activeFlags,
          originalTicket.representativeId,
          erotisi,
          thematicId,
          newBeneficiaryId || beneficiaryId,
          activeStatus,
          false,
          selectedUserForDirectAssignment?.id || originalTicket.assigneeUserId,
          teamIdToUse,
          selectedGeography && selectedGeography.value ? selectedGeography.value : null
      );


      // Check if teamId and thematicId are updated correctly
      if (response.teamId !== teamIdToUse || response.thematicId !== thematicId) {
        dispatch(showMessage({
          message: "Η ενημέρωση του ticket δεν ήταν επιτυχής",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "error",
        }));
      } else {
        if (groupAssignment) {
          setCurrentStep('groupAssignmentStep');
        } else {
          setCurrentStep('finalAction');
        }
      }
    } catch (error) {
      console.error('Error updating ticket', error);
      dispatch(showMessage({
        message: "Η ενημέρωση του ticket δεν ήταν επιτυχής",
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        variant: "error",
      }));
    }
  };

  const handleFinalAction = () => {
    onCompletion(true);
    dispatch(setShowCheckForChanges(false));
    setCurrentStep(null);
    onCloseModal();
  };


  const showErrorMessage = (error) => {
    const message = error.message;
    dispatch(
        showMessage({
          message,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "error",
        })
    );
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway' || reason === 'timeout') {
      // Reset actionStatus and openSnackbar to their initial states
      setActionStatus({ completed: false, success: false });
      setOpenSnackbar(false);
    }
  };


  return (
      <div>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                ContentProps={{
                  style: {
                    backgroundColor: actionStatus.success ? '#4e9a51' : '#b71c1c',
                  },
                }}
                onClose={handleCloseSnackbar}
                message={actionStatus.success ? "Η απευθείας ανάθεση ήταν επιτυχής" : "H απευθείας ανάθεση δεν ήτα επιτυχής"}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            />
      </div>
  );
}

export default CheckForChanges;