import React from 'react';
import { styled } from '@mui/system';
import {
  Select,
  MenuItem,
  Chip,
  Input,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { red, blue, grey, indigo } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveFlags } from 'store/knowledgeBasePageSlice';
import { showMessage } from 'store/messageSlice';
import {setFollowupTicketId} from "store/historyTicketSlice";

// Styled components
const StyledInputLabel = styled(InputLabel)({
  fontSize: '14px !important'
});


const StyledSelect = styled(Select)({
  backgroundColor: 'white',
  '&:focus': {
    backgroundColor: 'white',
  },
});

const StyledFormControl = styled(FormControl)({
  minWidth: '100%',
});

function FlagsTicket({isEditPage = false}) {
  const dispatch = useDispatch();
  const activeFlags = useSelector(
      (state) => state.knowledgeBasePageSlice.activeFlags
  );
  const activeStatus = useSelector((state) => state.ticketSlice.activeStatus);
  const beneficiaryHasOpenTickets = useSelector((state) => state.userInfoSlice.beneficiaryHasOpenTickets);
  const followUpTicketId = useSelector((state) => state.historyTicketSlice.followupTicketId);
  const flags = [
    { label: "Red Alert", color: red[500], value: "red alert", code: 100 },
    { label: "Priority", color: "#3ac376", value: "priorityFlg", code: 101 },
    { label: "Προσωρινές Συντάξεις", color: "#3AC3B8FF", value: "pensions", code: 102 },
    { label: "Φορείς-Παράπονα", color: "#be3ac3", value: "pensions", code: 103 },
    { label: "ΔΕΘ", color: "#003476", value: "deth", code: 104 }, // ΔΕΘ flag
    { label: "Follow up", color: blue[500], value: "followUpFlg", code: 200 },
    { label: "Ελλείψεις", color: grey[500], value: "bugReport", code: 300 },
    { label: "Αίτημα Ηχ/μένης κλήσης ΠΡΟΣΟΧΗ", color: "#e65100", value: "callEnd", code: 400 },
    { label: "Πρόβλημα πληροφοριακών Συστημάτων", color: indigo[500], value: "ringVolume", code: 500 },
    { label: "Παράπονα για το 1555", color: "#ba000d", value: "campaign", code: 800 },
    { label: "Καταγγελία Πολίτη", color: grey[900], value: "report", code: 900 },
    { label: "Media Alert / Special Case", color: "#FF6F61", value: "specialcase", code: 1000 },

  ];

  const handleFlagChange = (event) => {
    const selectedCodes = event.target.value;
    const previouslySelectedCodes = activeFlags.map((flag) => flag.code);

    // Determine if the code 200 is being added
    const isAddingFollowUpFlag = selectedCodes.includes(200) && !previouslySelectedCodes.includes(200);

    if (isAddingFollowUpFlag && !beneficiaryHasOpenTickets) {
      dispatch(showMessage({
        message: 'Δεν υπάρχουν ανοιχτά tickets',
        autoHideDuration: 10000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        variant: "error",
      }));
      return;
    }

    if (!selectedCodes.includes(200) && !isEditPage && followUpTicketId) {
      dispatch(setFollowupTicketId(null));
    }

    // Store the array of objects in Redux
    const selectedFlags = selectedCodes.map((code) => {
      const flag = flags.find((flag) => flag.code === code);
      return { code, name: flag ? flag.label : "" };
    });
    dispatch(setActiveFlags(selectedFlags));

    return selectedCodes;
  };


  return (
      <div className="w-full">
        <StyledFormControl fullWidth>
          <StyledInputLabel id="flags-select-label">Flags</StyledInputLabel>
          <StyledSelect
              labelId="flags-select-label"
              id="flags-select"
              multiple
              value={activeFlags.map((flag) => flag.code)}
              onChange={handleFlagChange}
              input={<Input />}
              disabled={activeStatus && activeStatus.id === 5}
              renderValue={(selected) => (
                  <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        minWidth: `${selected.length * 40}px`,
                        maxWidth: '1000px', // Maximum width
                      }}
                  >
                    {selected.map((code) => {
                      const flag = flags.find((flag) => flag.code === code);
                      if (!flag) {
                        console.error(`No flag found for code: ${code}`);
                        return null;
                      }

                      return (
                          <Chip
                              key={code}
                              label={flag.label}
                              className="mr-2 mb-2"
                              style={{
                                backgroundColor: flag.color,
                                color: 'white',
                              }}
                          />
                      );
                    })}
                  </div>
              )}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
          >
            {flags.map((flag) => (
                <MenuItem
                    key={flag.code}
                    value={flag.code}
                    disabled={flag.code === 104 || (flag.code === 200 && !beneficiaryHasOpenTickets)}
                >
                  <Checkbox
                      checked={activeFlags.some((activeFlag) => activeFlag.code === flag.code)}
                      disabled={
                          flag.code === 104 ||
                          (flag.code === 200 && (!beneficiaryHasOpenTickets || isEditPage)) ||
                          (flag.code === 200 && isEditPage)
                      }
                  />
                  <ListItemText primary={flag.label} style={{ color: flag.color }} />
                </MenuItem>

            ))}
          </StyledSelect>
        </StyledFormControl>
      </div>
  );
}

export default FlagsTicket;
