import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const ReleaseNotesDialog = ({ open, onClose }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={false}
            PaperProps={{
                style: {
                    width: '80%',
                    maxWidth: '1000px',
                    margin: '0 auto',
                    color: "black"
                },
            }}
        >
            <DialogTitle>Release Notes</DialogTitle>

            <DialogContent>
                <DialogContentText component="div" style={{color: 'black'}}>
                    <div>

                        <h2>Release Notes v1.9 – Ιανουάριος 2025</h2>
                        <h2>Προσθήκες</h2>
                        <ul>
                            <li><strong>-Πρότυπα Κείμενα ΒΟ:</strong> Προσθήκη λειτουργικότητας για τη δημιουργία
                                πρότυπων κειμένων ΒΟ και δυνατότητα απ’ ευθείας μεταφοράς απάντησης στην ερώτηση ticket.
                            </li>
                            <li><strong>-Νέο Flag: Media Alert/Ειδικές Περιπτώσεις:</strong> Εισαγωγή νέου flag για
                                ειδικές περιπτώσεις και ειδοποιήσεις media alert.
                            </li>
                            <li><strong>-Περιορισμοί Επεξεργασίας:</strong> Οι Team Leaders/Supervisors κατά την αλλαγή
                                θεματολογίας στην Επεξεργασία ενός ticket, δεν μπορούν πλέον να επιλέξουν ανενεργές
                                θεματικές.
                            </li>
                            <li><strong>-Μήνυμα Αναβάθμισης:</strong> Κάθε φορά που αλλάζει η έκδοση της εφαρμογής
                                ticketing, εμφανίζεται μήνυμα για ανανέωση της σελίδας από το χρήστη.
                            </li>
                            <li><strong>-Φίλτρο Ανοιχτών Tickets:</strong> Το φίλτρο \"Ανοιχτών Tickets\" στο CRM
                                περιλαμβάνει πλέον και τα tickets σε κατάσταση Reopen.
                            </li>
                        </ul>
                        <br/>
                        <h2>Release Notes v1.8 – Νοέμβριος 2024</h2>
                        <h2>Προσθήκες</h2>
                        <ul>
                            <li><strong>-Ρόλος Supervisor:</strong> Στο πάνω δεξιά avatar υπάρχει πλέον η
                                επιλογή <em>Δικαιούχοι</em>, που επιτρέπει τη διαχείριση δικαιούχων με αναζήτηση και
                                επεξεργασία προσωπικών στοιχείων.
                            </li>
                            <li><strong>Flag Follow Up:</strong> Όταν ένας δικαιούχος δεν έχει κανένα ανοιχτό ticket, το
                                flag <em>Follow Up</em> δεν είναι ενεργό.
                            </li>
                            <li>
                                <strong>-Δημιουργία Ticket με Flag Follow Up:</strong>
                                <ul>
                                    <li>-Κατά την επιλογή φορέα, ο χρήστης έχει τη δυνατότητα να επιλέξει με ποιο ticket
                                        συνδέεται το νέο follow up ticket.
                                    </li>
                                    <li>-Μετά την επιλογή, εμφανίζεται το λεκτικό <strong>Follow Up Id</strong> με τον
                                        αριθμό του ticket με το οποίο συνδέθηκε.
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <h2>-Προσθήκη Λειτουργικοτήτων στα Στοιχεία Δικαιούχου</h2>
                        <ul>
                            <li>Σήμανση <em>"Αλλαγή Στοιχείων Δικαιούχου"</em> όταν γίνονται αλλαγές σε δικαιούχο που
                                ήδη υπάρχει, με κουμπί <strong>Χ</strong> για απαλοιφή αλλαγών.
                            </li>
                            <br/>
                            <li><strong>Για δικαιούχους που έχουν πιστοποιηθεί μέσω taxis (π.χ., πολίτες που έχουν
                                καταχωρήσει
                                στο παρελθόν web ticket), το <em>Ονοματεπώνυμο</em> και το <em>ΑΦΜ</em> δεν
                                αλλάζουν.</strong>
                            </li>
                        </ul>
                        <br/>
                        <h2>Release Notes v1.7 – Οκτώβριος 2024</h2>
                        <br/>
                        <h3><strong>Προσθήκες</strong></h3>
                        <ul>
                            <li>-Καινούργιο φίλτρο αναζήτησης: Ανατεθειμένα σε μένα (Agent) / Στην ομάδα μου (Team
                                Leader). Με το συγκεκριμένο φίλτρο ο χρήστης βλέπει μόνο τα ανοιχτά ανατεθειμένα σε
                                αυτόν ή στην ομάδα του ticket, και όχι αυτά στα οποία έχει συνεισφέρει.
                            </li>
                            <li>-Το φίλτρο Κατάσταση συμπεριλαμβάνει by default και τα ticket reopen.</li>
                            <li>-Οι Team Leaders/Supervisors μπορούν να επιλέξουν πολλαπλές ομάδες στο φίλτρο "Ομάδα".
                            </li>
                            <li>-Η ταξινόμηση των ticket στη λίστα γίνεται πλέον με βάση την ημερομηνία τελευταίας
                                επεξεργασίας και όχι την ημερομηνία δημιουργίας των ticket.
                            </li>
                            <li>-Η ταξινόμηση μπορεί επίσης να γίνει με βάση την ημερομηνία δημιουργίας των ticket,
                                πατώντας πάνω στη στήλη "Ημερομηνία" (ctrl + click).
                            </li>
                        </ul>
                        <br/>
                        <hr/>
                        <br/>
                        <h2>Release Notes v1.6 – Ιούλιος 2024</h2>
                        <br/>
                        <h3><strong>Αλλαγές</strong></h3>
                        <ul>
                            <li>-Πλέον στην λίστα ticket, με διπλό κλικ γίνεται είσοδος στο ticket.</li>
                            <li>-Μετά 3 αποτυχημένες προσπάθειες σύνδεσης, πραγματοποιείται κλείδωμα του χρήστη για 5
                                λεπτά. Κατόπιν, ο χρήστης μπορεί να προσπαθήσει ξανά.
                            </li>
                            <li>-Στην δημιουργία ticket, αφού συμπληρωθεί το ΑΦΜ και εφ' όσον υπάρχει, εμφανίζεται
                                “Ιστορικό Δικαιούχου”. Με hover εμφανίζεται πίνακας μετα τα ticket που έχει κάνει κάθε
                                δικαιούχος ανά Φορέα, καθώς και την κατάστασή τους.
                            </li>
                            <li>-Στο CRM πολίτη υπάρχει η δυνατότητα φιλτραρίσματος των αιτημάτων με Φορέα.</li>
                            <li>-Οι Back Office και οι SA μπορούν να ανεβάζουν αρχεία - μόνο στα web ticket - και
                                μπορούν
                                να επιλέξουν ποια από τα αρχεία που ανεβάζουν μπορεί να δει ο πολίτης και ποια όχι.
                            </li>
                            <li>-Bug Fixes
                            </li>
                        </ul>
                        <br/>
                        <hr/>
                        <br/>
                        <br/>
                        <h2>Release Notes v1.5 – Date: 22/2/2024</h2>
                        <br/>
                        <h3><strong>Αλλαγές</strong></h3>
                        <ul>
                            <li>
                                Δικαιώματα προβολής ticket. Υπάρχουν 3 κανόνες προβολής ticket:
                                <ul>
                                    <li>-Οι agents βλέπουν τα ticket που έχουν δημιουργήσει, τους έχουν ανατεθεί, ή στα
                                        οποία έχουν συνεισφέρει.
                                    </li>
                                    <li>-Οι Team Leader βλέπουν τα ticket που έχουν ανατεθεί σε agents της ομάδας τους,
                                        ή
                                        ticket στα οποία έχουν συνεισφέρει.
                                    </li>
                                    <li>-Οι Supervisor βλέπουν όλα τα ticket του Φορέα στον οποίο ανήκουν.</li>
                                </ul>
                            </li>
                            <li>-Έγινε αλλαγή στον τρόπο που μετακινούνται τα ticket. Τα ticket μετακινούνται μόνο από
                                και προς τους agent - συνεπώς και τις ομάδες - που προήλθαν.
                            </li>
                            <li>-Στην οθόνη «Λίστα ticket» η προεπιλεγμένη σειρά εμφάνισης είναι να φαίνονται τα
                                ανοιχτά,
                                και τα πιο πρόσφατα ticket πρώτα. Το φίλτρο μπορεί να διαγραφεί από το αντίστοιχο πεδίο
                                και να εμφανιστούν όλα τα ticket.
                            </li>
                            <li>-Η Πύλη Προέλευσης του ticket (web app, τηλεφωνικό) πλέον εμφανίζεται με εικονίδια και
                                όχι με λεκτικά.
                            </li>
                            <li>-Η συμπλήρωση του Αντιπροσώπου έχει ενσωματωθεί και στην Επεξεργασία Ticket. Στην
                                συμπλήρωση υπάρχουν υποχρεωτικά πεδία.
                            </li>
                            <li>-Επιστροφή ticket στο Front Office: όταν επιστρέφει ticket από το Back Office ή το SA
                                προς το FO, τότε το ticket ανατίθεται στο ίδιο άτομο που το δημιούργησε. Εάν δεν βρεθεί
                                το ίδιο άτομο, τότε το ticket ανατίθεται αυτόματα στον πρώτο διαθέσιμο Team Leader της
                                ίδιας ομάδας.
                            </li>
                        </ul>
                        <br/>
                        <hr/>
                        <br/>
                        <h3><strong>Προσθήκες</strong></h3>
                        <ul>
                            <li>UPDATE/ SAVE TICKET: στην επεξεργασία ticket, για κάθε αλλαγή που πραγματοποιείται,
                                εμφανίζεται κουμπί “Αποθήκευση” ή “Ακύρωση”. Με την αποθήκευση, εμφανίζεται παράθυρο
                                διαλόγου για:
                                <ul>
                                    <li>-Επιβεβαίωση των αλλαγών</li>
                                    <li>-Διαγραφή όλων των αλλαγών ή,</li>
                                    <li>-Συνέχιση επεξεργασίας.</li>
                                </ul>
                                Με την επιβεβαίωση αποθηκεύονται όλες οι αλλαγές στο ticket. Εάν ο χρήστης επιλέξει την
                                ακύρωση, τότε αναιρούνται όλες οι αλλαγές και το ticket γυρνάει στην προηγούμενη
                                κατάσταση.
                            </li>
                            <li>-Στα web ticket, το ΒΟ μπορεί να αναρτήσει αρχεία.</li>
                            <li>-Στο CRM, στο Ιστορικό Ticket, φαίνεται η ημερομηνία (δεξιά στήλη) που έγινε κάθε
                                ενέργεια στο ticket.
                            </li>
                            <li>-Σύνδεση με κωδικούς Δημόσιας Διοίκησης: Όταν συνδέεται ΒΟ ή SA agent για πρώτη φορά με
                                κωδικούς Δημόσιας Διοίκησης, τότε απενεργοποιείται αυτόματα η δυνατότητα εισόδου με τους
                                κωδικούς που δημιουργούνται αυτόματα από το σύστημα. Δυνατότητα αναίρεσης αυτής της
                                λειτουργίας έχει ο διαχειριστής συστήματος.
                            </li>
                            <li>-Στην Λίστα ticket, στα πεδία αναζήτησης, έχει προστεθεί η δυνατότητα για προβολή των
                                ticket που έχουν προωθηθεί από το SA προς το ΒΟ.
                            </li>
                            <li>-Μεταφορά από FO προς SA: Το Front Office έχει την δυνατότητα να μεταφέρει απ’ευθείας
                                αιτήματα προς το SA.
                            </li>
                            <li>-Αναζήτηση στην λίστα ticket με βάση την Πλατφόρμα Προέλευσης (web/ τηλεφωνικό).</li>
                            <li>-Για τους Supervisor, προσετέθη στα πεδία αναζήτησης της Λίστας ticket κουμπί αναζήτησης
                                agent με βάση τον ρόλο και το επώνυμο.
                            </li>
                            <li>-Για τους Supervisor, προσετέθη στα πεδία αναζήτησης της Λίστας ticket κουμπί αναζήτησης
                                για τις απαντητικές ομάδες.
                            </li>
                            <li>-Προσθήκη λειτουργικότητας Συχνών Ερωτήσεων και δυνατότητας απ’ ευθείας μεταφοράς
                                απάντησης στην ερώτηση ticket.
                            </li>
                            <li>-Κουμπί «Ανανέωση», το οποίο ανανεώνει την τρέχουσα σελίδα.</li>
                            <li>-Ταξινόμηση ticket με βάση την ημερομηνία (από τον πίνακα ticket) και ταξινόμηση με βάση
                                το νεότερο ή το παλαιότερο πρώτα.
                            </li>
                        </ul>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Κλείσιμο</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReleaseNotesDialog;
