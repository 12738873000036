import React from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Link } from 'react-router-dom';
import GetAppIcon from '@mui/icons-material/GetApp';
import NotesIcon from '@mui/icons-material/Notes';
import CampaignIcon from "@mui/icons-material/Campaign";

const drawerMenuItems = [
  {
    id: 'tickets-page',
    title: <Link to="/tickets" style={{ textDecoration: 'none', color: 'inherit' }}>Λίστα Tickets</Link>,
    type: 'item',
    icon: <ViewListIcon  />,
    url: 'tickets',
  },
  {
    id: 'knowledge-page',
    title: <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Δημιουργία Ticket</Link>,
    type: 'item',
    icon: <AddCircleOutlineIcon />,
    url: 'create-ticket',
  },
  {
    id: 'announcments',
    title: <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Ανακοινώσεις</Link>,
    type: 'item',
    icon: <CampaignIcon  />,
    url: 'announcments',
  },
// Download Link
  {
    id: 'download-link',
    title: "Οδηγός 1555",
    type: 'item',
    icon: <GetAppIcon />,
    url: '/pdf/1555 manualv09122024.pdf',
    action: 'download',
  },
// Action for opening a dialog
  {
    id: 'release-notes',
    title: "Release Notes",
    type: 'dialog-trigger',
    icon: <NotesIcon />,
    url: '',
    action: 'openDialog',
  }
];

export default drawerMenuItems;
