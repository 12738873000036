import React, {useState, useEffect} from 'react';
import {EditorState, convertFromRaw, convertToRaw, Modifier} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useDispatch, useSelector} from 'react-redux';

import {resetPastedFlag, setErotisiEditorState, validateErotisiText} from 'store/userInfoSlice';
import DOMPurify from 'dompurify';
import {stateToHTML} from 'draft-js-export-html';
import {convertFromHTML, ContentState, SelectionState} from 'draft-js';
import {stateFromHTML} from "draft-js-import-html";

function WysiwigEditor() {
    const toolbar = {
        options: ['blockType', 'fontSize', 'fontFamily', 'list', 'remove', 'history', 'inline'],
        blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
        },
        fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24],
        },
        fontFamily: {
            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
        },
        list: {
            inDropdown: false,
            options: ['unordered', 'ordered'],
        },
        inline: {
            inDropdown: false,
            options: ['bold'],
        },
    };

    const erotisiContent = useSelector((state) => state.userInfoSlice.erotisi);
    const dispatch = useDispatch();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const isPasted = useSelector(state => state.userInfoSlice.isPastedErotisi);

    useEffect(() => {
        if (isPasted) {
            const contentState = stateFromHTML(erotisiContent || "");
            const newEditorState = EditorState.createWithContent(contentState);

            setEditorState(newEditorState);
            dispatch(resetPastedFlag());
        }
    }, [isPasted, dispatch]);



    const handlePasteHtmlContent = (htmlContent) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlContent);
        const contentState = stateFromHTML(sanitizedHtml);
        const newEditorState = EditorState.createWithContent(contentState);

        setEditorState(newEditorState);
    };


    const onChangeErotisi = (newEditorState) => {
        const currentContent = newEditorState.getCurrentContent();
        const html = DOMPurify.sanitize(stateToHTML(currentContent));

        // Check if the editor content is empty
        const isContentEmpty = !currentContent.hasText() && currentContent.getBlockMap().size === 1 && currentContent.getFirstBlock().getType() === 'unstyled' && currentContent.getFirstBlock().getLength() === 0;

        if (isContentEmpty) {
            // If the content is empty, dispatch an action to set erotisi to an empty string
            dispatch(setErotisiEditorState(''));
        } else if (editorState.getCurrentContent() !== currentContent) {
            // Dispatch actions only if the content has changed significantly and is not empty
            dispatch(setErotisiEditorState(html));
            dispatch(validateErotisiText(currentContent.getPlainText()));
        }

        setEditorState(newEditorState);
    };


    return (
        <div className="w-6/12 h-[250px]">
            <Editor
                editorState={editorState}
                onEditorStateChange={onChangeErotisi}
                wrapperClassName="border border-gray-300 rounded mb-4 w-full max-h-[200px] h-[200px]"
                editorClassName="p-4 overflow-y-auto break-words bg-white w-full mr-9"
                placeholder="Περιγραφή Συζήτησης"
                toolbar={toolbar}
            />
        </div>
    );
}

export default WysiwigEditor;
